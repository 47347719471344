// Styles
import './main.css'

// Carousel
import Splide from '@splidejs/splide'
window.Splide = Splide

// Decimals to fractions (Recipes)
import { formatQuantity } from 'format-quantity';
window.formatQuantity = formatQuantity

// Lazy image loading
import 'lazysizes'

// iFrame resizing
import iframeResize from 'iframe-resizer/js/iframeResizer.min.js'
window.iframeResize = iframeResize

// Alpine
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import persist from '@alpinejs/persist'
import intersect from '@alpinejs/intersect'

Alpine.plugin(collapse)
Alpine.plugin(focus)
Alpine.plugin(persist)
Alpine.plugin(intersect)

window.Alpine = Alpine

// Buy Titos (Alpine module)
import reservebar from './js/reservebar.js'
reservebar()

Alpine.start()


// Formie age validator
let $form = document.querySelector('form[id^=fui-]');
if ($form) {
    $form.addEventListener('onFormieThemeReady', (event) => {
        event.detail.addValidator('minAge', ({ input }) => {

            const minAge = input.getAttribute('data-age-min');

            if (!minAge) {
              return true;
            }

            let $month = $form.querySelector("[name='fields[birthday][month]']");
            let $day = $form.querySelector("[name='fields[birthday][day]']");
            let $year = $form.querySelector("[name='fields[birthday][year]']");

            let $date = new Date($year.value, $month.value - 1, $day.value);
            let $today = new Date();
            $today.setHours(0, 0, 0, 0);
            $date.setHours(0, 0, 0, 0);
            const difference = $today - $date;
            const age = difference / (1000 * 3600 * 24 * 365.25);
            const ageYears = Math.floor(age);

            // console.log('Age: ', ageYears);

            if (ageYears < minAge) {
                return false;
            }

            return true;
        }, `You must be 21 or older to sign up.`);
    });
}
