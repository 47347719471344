export default function () {
	document.addEventListener('DOMContentLoaded', () => {
		// Add cart class to nav links to only show cart button on this page
		const rightNav = document.getElementById('nav__links--right')
		if (rightNav) {
			rightNav.classList.add('-cart')
		}
	})

	document.addEventListener('alpine:init', () => {
		Alpine.data('reservebar', () => ({
			step: 1,
			stateDisclaimer: false,
			address: { address1: '', city: '', state: '', zipCode: '' },
			selectedProduct: null,
			loading: true,
			sizeCallback(alpine) {
				const groupSizeButtons = document.querySelector(
					'.liquid-product-group-size-buttons',
				)
				console.log(this.selectedProduct)
				if (groupSizeButtons) {
					let selectedSize =
						groupSizeButtons.querySelector(
							'button.active',
						).innerHTML
					this.selectedProduct = selectedSize
					this.step = 3
					let el = document.querySelector(
						`[data-size="${selectedSize.toLowerCase()}"]`,
					)
					if (el) {
						let src = el.src
						mainImg.src = src
						curSize.classList.remove('block')
						curSize.classList.add('hidden')
						el.classList.remove('hidden')
						el.classList.add('block')
						curSize = el
					}
				}
			},
			cartCallback() {
				const liquidCart = document.querySelector('[liquid-cart]')
				const cartBottom = liquidCart.querySelector(
					'.liquid-cart-bottom-container',
				)
				const cartCheckout = liquidCart.querySelector(
					'.liquid-checkout-button',
				)
				const continueShoppingButton =
					liquidCart.querySelector('#continue-shopping')
				const cartDisclaimer =
					liquidCart.querySelector('#cart-disclaimer')

				if (cartBottom && !continueShoppingButton) {
					let continueShopping = document.createElement('button')
					continueShopping.setAttribute('id', 'continue-shopping')
					continueShopping.innerText = 'Continue Shopping'
					continueShopping.onclick = function () {
						liquidSDK.cart.toggle()
					}
					cartBottom.append(continueShopping)
				}
				if (cartCheckout && !continueShoppingButton) {
					let cartDisclaimer = document.createElement('p')
					cartDisclaimer.setAttribute('id', 'cart-disclaimer')
					cartDisclaimer.innerHTML =
						'By placing this order, I give consent to Tito’s Handmade Vodka to process and store the personal data on this form in accordance with its <a href="https://www.titosvodka.com/terms-of-use">Terms</a> and <a href="https://www.titosvodka.com/privacy">Privacy Policy</a>. The personal data submitted via this form will be retained for transactional order fulfillment communications.'
					cartBottom.insertBefore(cartDisclaimer, cartCheckout)
				}
			},
			resetAddress() {
				liquidSDK.session.resetAddress()
				this.address = liquidSDK.address.get()
				this.step = 1
			},
			moveSteps(index) {
				// Update values
				this.step = index ?? 1
				this.address = liquidSDK.address.get()
			},
			init() {
				let alpine = this

				// Reserve Bar Init
				liquidSDK
					.init({
						clientId: 'a0de15907150db98794aa37265a95a04',
						includeMerchandise: true,
						webLockupCodeword: 'mini1234',
						transformers: {
							productGroup: {
								price: (productGroup) =>
									productGroup
										? `$${parseFloat(productGroup.variant.price).toFixed(2)}`
										: '',
							},
						},
					})
					.then((data) => {
						alpine.loading = false
						alpine.address = liquidSDK.address.get()
					})

				// Address validation
				liquidSDK.address.subscribe((data) => {
					if (!data || !data.state) {
						return
					}

					if (data.state == 'IL') {
						alpine.stateDisclaimer = true
					} else if (data.state != null) {
						alpine.stateDisclaimer = false
						alpine.moveSteps(2)
					}

					liquidSDK.session.resetAddress()
				})

				liquidSDK.cart.subscribe((data) => {
					if (alpine.address.state == 'IL') {
						let cartItems = data.cartItems
						cartItems.forEach((item) => {
							liquidSDK.cart.remove(item.identifier)
						})
					}
				})

				const liquidCart = document.querySelector('[liquid-cart]')
				const cartObserver = new MutationObserver(alpine.cartCallback)
				cartObserver.observe(liquidCart, {
					attributes: true,
					childList: true,
					subtree: true,
				})

				const dispatchEvent = () =>
					this.$root.dispatchEvent(new CustomEvent('sizecallback'))
				const sizeObserver = new MutationObserver(dispatchEvent)
				sizeObserver.observe(
					document.querySelector('[liquid-size-buttons]'),
					{ attributes: true, childList: true, subtree: true },
				)
				this.$root.addEventListener('sizecallback', (e) => {
					alpine.sizeCallback()
				})
			},
		}))
	})
}
